<script setup>
const props = defineProps({
  active: {
    type: Boolean,
    default: false,
  },
})
</script>
<template>
  <svg
    role="img"
    aria-label="home icon"
    class="home-icon o-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 28 28"
  >
    <title>Home</title>
    <path
      v-if="!props.active"
      d="M14 4.53L24.45 11.81L20.48 23.51H18.25V20.3C18.25 18.37 16.68 16.81 14.76 16.81H13.25C11.32 16.81 9.76 18.38 9.76 20.3V23.51H7.5L3.53 11.82L14 4.54M14 3.01C13.73 3.01 13.46 3.09 13.22 3.25L2.58 10.64C2.09 10.98 1.88 11.6 2.07 12.17L6.11 24.09C6.3 24.64 6.81 25 7.39 25H10.25C10.8 25 11.25 24.55 11.25 24V20.29C11.25 19.19 12.14 18.3 13.24 18.3H14.75C15.85 18.3 16.74 19.19 16.74 20.29V24C16.74 24.55 17.19 25 17.74 25H20.58C21.16 25 21.67 24.63 21.86 24.09L25.9 12.17C26.09 11.6 25.89 10.98 25.39 10.64L14.78 3.24C14.55 3.08 14.27 3 14 3V3.01Z"
    />
    <path
      v-else
      d="M25.4 10.64L14.78 3.24C14.55 3.08 14.27 3 14 3C13.73 3 13.46 3.08 13.22 3.24L2.58 10.64C2.09 10.98 1.88 11.6 2.07 12.17L6.11 24.09C6.3 24.64 6.81 25 7.39 25H10.25C10.8 25 11.25 24.55 11.25 24V20.29C11.25 19.19 12.14 18.3 13.24 18.3H14.75C15.85 18.3 16.74 19.19 16.74 20.29V24C16.74 24.55 17.19 25 17.74 25H20.58C21.16 25 21.67 24.63 21.86 24.09L25.9 12.17C26.09 11.6 25.89 10.98 25.39 10.64H25.4Z"
    />
  </svg>
</template>

<style lang="scss" scoped>
.home-icon {
  fill: var(--text-color);
  height: 52px;
}
</style>
