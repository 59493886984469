<script setup>
const props = defineProps({
  active: {
    type: Boolean,
    default: false,
  },
})
</script>
<template>
  <svg
    role="img"
    aria-label="live icon"
    class="live-icon o-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 28 28"
  >
    <title>Live</title>

    <path
      v-if="!props.active"
      d="M13.98 12.48C14.81 12.48 15.48 13.15 15.48 13.98C15.48 14.81 14.81 15.48 13.98 15.48C13.15 15.48 12.48 14.81 12.48 13.98C12.48 13.15 13.15 12.48 13.98 12.48ZM13.98 10.98C12.32 10.98 10.98 12.32 10.98 13.98C10.98 15.64 12.32 16.98 13.98 16.98C15.64 16.98 16.98 15.64 16.98 13.98C16.98 12.32 15.64 10.98 13.98 10.98Z"
    />
    <path
      v-else
      d="M13.98 16.98C15.6369 16.98 16.98 15.6368 16.98 13.98C16.98 12.3231 15.6369 10.98 13.98 10.98C12.3232 10.98 10.98 12.3231 10.98 13.98C10.98 15.6368 12.3232 16.98 13.98 16.98Z"
    />
    <path
      d="M5.22001 23.64C5.03001 23.64 4.84001 23.57 4.69001 23.42C2.14001 20.91 0.730011 17.55 0.730011 13.98C0.730011 10.41 2.14001 7.04 4.70001 4.53C5.00001 4.24 5.47001 4.24 5.76001 4.53C6.05001 4.83 6.05001 5.3 5.76001 5.59C3.49001 7.82 2.24001 10.8 2.24001 13.97C2.24001 17.14 3.49001 20.11 5.75001 22.34C6.04001 22.63 6.05001 23.11 5.75001 23.4C5.60001 23.55 5.41001 23.62 5.22001 23.62V23.64Z"
    />
    <path
      d="M9.26999 20.29C9.07999 20.29 8.88999 20.22 8.74999 20.08C7.08999 18.46 6.17999 16.29 6.17999 13.98C6.17999 11.67 7.09999 9.49997 8.75999 7.86997C9.05999 7.57997 9.52999 7.58997 9.81999 7.87997C10.11 8.17997 10.1 8.64997 9.80999 8.93997C8.43999 10.28 7.68999 12.06 7.68999 13.97C7.68999 15.88 8.43999 17.66 9.80999 19C10.11 19.29 10.11 19.76 9.81999 20.06C9.66999 20.21 9.47999 20.29 9.27999 20.29H9.26999Z"
    />
    <path
      d="M22.65 23.64C22.46 23.64 22.26 23.57 22.11 23.42C21.82 23.12 21.82 22.65 22.11 22.36C24.38 20.13 25.63 17.15 25.63 13.98C25.63 10.81 24.38 7.84001 22.12 5.61001C21.83 5.32001 21.82 4.84001 22.12 4.55001C22.41 4.25001 22.88 4.25001 23.18 4.55001C25.73 7.06001 27.14 10.42 27.14 13.99C27.14 17.56 25.73 20.93 23.17 23.44C23.02 23.58 22.83 23.65 22.64 23.65L22.65 23.64Z"
    />
    <path
      d="M18.6 20.29C18.4 20.29 18.21 20.21 18.06 20.06C17.77 19.76 17.78 19.29 18.07 19C19.44 17.66 20.19 15.88 20.19 13.97C20.19 12.06 19.44 10.28 18.07 8.94001C17.77 8.65001 17.77 8.18001 18.06 7.88001C18.35 7.58001 18.83 7.58001 19.12 7.87001C20.78 9.49001 21.69 11.66 21.69 13.97C21.69 16.28 20.77 18.45 19.11 20.08C18.96 20.22 18.78 20.29 18.59 20.29H18.6Z"
    />
  </svg>
</template>

<style lang="scss" scoped>
.live-icon {
  fill: var(--text-color);
  height: 52px;
}
</style>
